import { IconButton, List, ListItem, MenuItem, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { css } from "@emotion/react"
import { GRAY } from "../../../theme";
import { Box, Stack } from "@mui/system";
import { Download, Image, Upload } from "react-feather";
import pdfIcon from "../../../shared/img/pdfIcon.png";
import { LABLE_REQUEST } from "./ReviewRequest";
import dayjs from "dayjs";
import SolicitationService from "../../../services/SolicitationService";
import { saveAs } from 'file-saver';
import { StatusANTTEnum } from "../../../shared/utils/enum";
import DocumnetService from "../../../services/DocumnetService";
import { useState } from "react";
import { Spin } from "antd";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import UploadModal from "./Components/UploadModal";


const CarrierDocument = ({ solicitationDocsList, cnaeOptions, apiNotification, getCarrierDetails }) => {

    const [load, setLoad] = useState(false);
    const [file, setFile] = useState();
    const location = useLocation();
    const [uploadModalOpen, setUploadModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState();
    const [valueVariable, setValueVariable] = useState();

    const handleDownload = async (id, fileName) => {
        await SolicitationService.downloadFileItem(id).then(resp => {
            const base64Response = resp.data.fileContents;
            const binary = atob(base64Response.replace(/\s/g, ''));
            const len = binary.length;
            const buffer = new ArrayBuffer(len);
            const view = new Uint8Array(buffer);
            for (let i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
            }
            const blob = new Blob([view], { type: 'application/octet-stream' });
            saveAs(blob, fileName);
        })
    }

    const handleFile = (e) => {
        const fileValue = e?.target?.files ? Array.from(e.target.files)[0] : null;
        setFile(fileValue);

    }

    const sendFile = async (variableName) => {
        setLoad(true);
        const formatData = new FormData();
        formatData.append('file', file);
    
        await DocumnetService.sendDocsYara(location.state.carrierId, variableName, formatData, valueVariable).then(() => {
            setTimeout(() => {
                apiNotification['success']({
                    message: 'Documento enviado!',
                    placement: 'topRight',
                    duration: 5
                })
                getCarrierDetails(location.state.carrierId);
                onCancelUpload();
                setLoad(false);
            }, 1000)
        });
    }

    const onCancelUpload = () => {
        setUploadModalOpen(false);
        setFile(null);
        setValueVariable('');
    }

    function isDateValid(dateStr) {
        return !isNaN(new Date(dateStr));
    }

    const onChangeValue = (e) => {
        if (isDateValid(e)) {
            setValueVariable(dayjs(e).format());
        } else {
            setValueVariable(e.target.value);
        }
    }

    const handleValueItemRender = (value, variableName) => {
        if (value === null) return "--";

        return value?.length > 0 && variableName.includes('Validity') && !isNaN(Date.parse(value)) ? dayjs(value).format('DD/MM/YYYY') : variableName.includes('ANTT') || variableName.includes('statusSocialContractId') ? StatusANTTEnum.find(el => el.anttStatusId === parseInt(value))?.anttStatusName : variableName.includes("cnaeTypeId") ?
            cnaeOptions?.find(cnae => cnae.cnaeTypeId === parseInt(value))?.cnaeTypeName : value ?? '--'
    }

    return (<Box padding={2}>
        {solicitationDocsList.length > 0 ?
            <List>
                <Spin spinning={load} fullscreen />
                {solicitationDocsList.map(item => {
                    return <ListItem>
                        <Table>
                            <TableHead>
                                <TableHead sx={css`background-color: white;`}>
                                    <TableRow >
                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"35%"}>{LABLE_REQUEST[item.documentVariable]}</TableCell>
                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"25%"}></TableCell>
                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"20%"}>Adicionado por</TableCell>
                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"10%"}>Data de inclusão</TableCell>
                                        <TableCell sx={css`font-size: 14px;color: ${GRAY};padding: 16px 20px 0px 0px;border-bottom: none;`} width={"10%"}></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody sx={css`background-color: white;`}>
                                    <TableRow >
                                        <TableCell sx={css`font-size: 16px;color: #000000;padding: 16px 20px 20px 0px;`}>{handleValueItemRender(item.fieldValue, item.documentVariable)}</TableCell>
                                        <TableCell sx={css`font-size: 16px;font-weight: bold;color: #00205B;padding: 16px 20px 20px 0px;cursor: pointer;`} onClick={() => item.solicitationItem?.extension ? handleDownload(item.solicitationItem.solicitationItemId, LABLE_REQUEST[item.documentVariable] + item.solicitationItem.extension) : null}>{item.solicitationItem?.extension ? <Stack spacing={1} direction={'row'}> {item.solicitationItem?.extension.includes('.pdf') ? <img src={pdfIcon} /> : <Image />}<div style={{ textDecoration: 'underline', display: 'inline' }}>{LABLE_REQUEST[item.documentVariable] + item.solicitationItem?.extension}</div></Stack> : '--'} </TableCell>
                                        <TableCell sx={css`font-size: 16px;color: #000000;padding: 16px 20px 20px 0px;`}>{item.solicitationItem?.solicitation?.userName ? item.solicitationItem?.solicitation?.userName : '--'}</TableCell>
                                        <TableCell sx={css`font-size: 16px;color: #000000;padding: 16px 20px 20px 0px;`}>{item.solicitationItem?.solicitation?.dataInc ? dayjs(item.solicitationItem?.solicitation?.dataInc).format('DD/MM/YYYY') : '--'}</TableCell>
                                        <TableCell sx={css`font-size: 16px;color: #000000;padding: 16px 20px 20px 0px;`}>{ }</TableCell>
                                        <TableCell sx={css`font-size: 16px;color: #000000;padding: 16px 20px 20px 0px;`}><IconButton component="label" onClick={() => {
                                            setUploadModalOpen(true)
                                            setSelectedDocument(item.documentVariable)
                                        }}>
                                            <Upload color="#2777B8" />
                                        </IconButton> </TableCell>
                                    </TableRow>
                                </TableBody>
                            </TableHead>
                        </Table>
                        <UploadModal
                            open={uploadModalOpen && selectedDocument === item.documentVariable}
                            load={load}
                            onCancel={onCancelUpload}
                            handleFile={handleFile}
                            sendFile={sendFile}
                            variableName={item.documentVariable ? item.documentVariable : ''}
                            valueVariable={valueVariable}
                            onChangeVariable={onChangeValue}
                            file={file}
                            cnaesOptions={cnaeOptions.map((cnae) => (
                                <MenuItem key={cnae.cnaeTypeId} value={cnae.cnaeTypeId}>
                                    {cnae.cnaeTypeName}
                                </MenuItem>
                            ))}
                            anttOptions={StatusANTTEnum.map((antt) => (
                                <MenuItem key={antt.anttStatusId} value={antt.anttStatusId}>
                                    {antt.anttStatusName}
                                </MenuItem>
                            ))}
                        />
                    </ListItem>
                })}
            </List>
            : ''}
    </Box>)
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default CarrierDocument;