import { Truck, Users, Edit, MessageSquare } from "react-feather";
import { ReactComponent as ErrorIcon } from "../img/Error.svg";
import { ReactComponent as SuccessIcon } from "../img/Success.svg";
import { ReactComponent as WarnIcon } from "../img/Warning.svg";
import { ReactComponent as InfoIcon } from "../img/Info.svg";
import {
    DARK_GRAY,
    RED,
    SECONDARY_COLOR,
    YELLOW,
    LIGHT_GREEN,
    THIN_YELLOW,
    THIN_RED,
    THIN_GREEN,
    LIGHT_BLUE
} from "../../theme";
import { routes } from "./Constants";

export const environment = {
    production: { code: "production" },
    homologation: { code: "homologation" },
    rollout: { code: "rollout" },
    development: { code: "development" },
    local: { code: "local" }
};

export const deliveryVersion = [
    {
        version: "1.0.0",
        delivery: ""
    }
];

export const accessType = {
    logisticsAnalyst: "Logistics Analyst",
    fullView: "Full View",
    carrier: "Carrier",
    hiring: "Hiring"
};

export const sidebarItemsNames = {
    mainPage: "Transportadoras",
    accessManagement: "Controle de acesso",
    solicitations: "Solicitações de Edição",
    instructionsSent: "Instruções enviadas",
    addInstructions: "Adicionar instruções",
    newsLetter: "Comunicados"
};

export const sidebarItems = [
    {
        icon: <Truck color={DARK_GRAY} size={32} />,
        name: sidebarItemsNames.mainPage,
        route: routes.listCarriers,
        profiles: [accessType.logisticsAnalyst, accessType.fullView]
    },{
        icon: <Users color={DARK_GRAY} size={32} />,
        name: sidebarItemsNames.accessManagement,
        route: routes.usersList,
        profiles: [accessType.logisticsAnalyst]
    }, {
        icon: <Edit color={DARK_GRAY} size={32} />,
        name: sidebarItemsNames.solicitations,
        route: routes.solicitationsList,
        profiles: [accessType.logisticsAnalyst]
    },{
        icon: <MessageSquare color={DARK_GRAY} size={32} />,
        name: sidebarItemsNames.newsLetter,
        route: routes.newsLetter,
        profiles: [accessType.logisticsAnalyst, accessType.fullView, accessType.carrier, accessType.hiring]
    }
];

export const toastTypes = {
    error: {
        type: "error",
        primaryColor: THIN_RED,
        secondaryColor: RED,
        icon: <ErrorIcon />
    },
    warning: {
        type: "warning",
        primaryColor: THIN_YELLOW,
        secondaryColor: YELLOW,
        icon: <WarnIcon />
    },
    success: {
        type: "success",
        primaryColor: THIN_GREEN,
        secondaryColor: LIGHT_GREEN,
        icon: <SuccessIcon />
    },
    information: {
        type: "information",
        primaryColor: LIGHT_BLUE,
        secondaryColor: SECONDARY_COLOR,
        icon: <InfoIcon />
    }
};

export const homologationStatuses = {
    HOMOLOGATED: "Homologada",
    BLOCKED: "Bloqueada",
    SUSPENDED: "Suspensa",
    HOMOLOGATION: "Em homologação"
};

export const serasaScoreLevels = {
    LOW: "Baixo",
    MEDIUM: "Médio",
    HIGH: "Alto"
};

export const statusIdd = {
    kof: "KOF",
    approved: "Approved",
    awaitingRefreshDecision: "Awaiting Refresh Decision",
    awaitingIDDQuestionnaireFromBP: "Awaiting IDD Questionnaire from BP",
    awaitingFeedbackFromBS: "Awaiting feedback from BS",
    underDAReview: "Under DA Review",
    rejected: "Rejected",
    cancelled: "Cancelled",
    terminated: "Terminated"
};

export const carrierStatus = {
    active: {
        id: 1,
        name: "Ativa"
    },
    inactive: {
        id: 2,
        name: "Inativa"
    }
};

export const carrierFilterOptions = {
    status: "status",
    productgroups: "productgroups",
    regional: "regional",
    towers: "towers"
};

export const InputVerifyDataTable = {
    PHONE: 0,
    EMAIL: 1,
    CPF: 2
}

export const ContactCarreirProfileType = {
	REGULATORY: 1,
	REPRESENTATIVE: 2,
	COMERCIAL: 3
}


export const IdentityProviders = {
    B2B: 'B2B',
    B2C: 'B2C'
}

export const StatusANTTEnum = [
    {
        anttStatusId: 1,
        anttStatusName: "ATIVO"
    },{
        anttStatusId: 2,
        anttStatusName: "INATIVO"
    }
]
