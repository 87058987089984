import { Button, Dialog, DialogActions, DialogTitle, FormControl, FormLabel, Grid, IconButton, InputAdornment, MenuItem, TextField, Typography } from "@mui/material";
import styled from "@emotion/styled"
import { BodyDialog, HeaderDialog } from "../../../usersList/view/NewAccessModal";
import { css } from "@emotion/react";
import { Close } from "@mui/icons-material";
import { CancelButton, ConfirmButton } from "../../../carrierDetails/views/RequestEditCarrier";
import { Textarea } from "@mui/joy";
import { LIGHT_BLUE, LIGHT_GRAY, RED, SECONDARY_COLOR, WHITE } from "../../../../theme";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Calendar, Trash2 } from "react-feather";
import { Stack } from "@mui/system";
import { Spin } from "antd";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ModalDatePicker, ModalSelectForm, ModalTextField } from "../CarrierModalEdit";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ptBR } from "date-fns/locale";
import { useEffect, useState } from "react";
import { parseISO } from "date-fns";
import { LABLE_REQUEST } from "../ReviewRequest";


const UploadModal = ({ load, open, variableName, valueVariable, onChangeVariable, sendFile, onCancel, handleFile, file, cnaesOptions, anttOptions }) => {
    const [width, setWidth] = useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 769;

    const DatePickerBarClear = ({
        onAccept,
        onCancel,
        onClear,
        _onSetToday,
        className
    }) => {

        return (
            <DialogActions className={className}>
                <Button onClick={onClear}> LIMPAR </Button>
                <Button onClick={onCancel}> CANCELAR </Button>
                <Button onClick={onAccept}> OK </Button>
            </DialogActions>
        );
    };


    return <UploadDialog
        open={open}
        onClose={onCancel}
    >
        <Spin spinning={load}>
            <HeaderDialog>
                <DialogTitle sx={css` color: white;`}>Fazer upload de Documento </DialogTitle>
                <Close sx={css`cursor: pointer;color: white;margin-right: 16px;`} onClick={onCancel} />
            </HeaderDialog>
            <BodyDialog>
                <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item md={12} sm={8} xs={4} sx={css`font-size: 16px;color: #000000;`}>
                        {LABLE_REQUEST[variableName]}
                    </Grid>
                    <Grid item md={5} sm={8} xs={4}>
                        {variableName?.includes('Validity') ? <FormControl component={'fieldset'} sx={css`width:100%;`}>
                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Data de vencimento</FormLabel>
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBR}>
                                <ModalDatePicker
                                    defaultValue={parseISO(valueVariable)}
                                    onChange={onChangeVariable}
                                    format="dd/MM/yyyy"
                                    inputVariant="outlined"
                                    variant="outlined"
                                    disable
                                    size="small"
                                    cancelLabel="CANCELAR"
                                    InputLabelProps={{ shrink: false }}
                                    slots={{
                                        actionBar: isMobile ? DatePickerBarClear : undefined
                                    }}
                                    slotProps={{
                                        field: { clearable: true, onClear: onChangeVariable },
                                        actionBar: isMobile ? { actions: ['clear', 'cancel', 'accept'] } : undefined
                                    }}
                                    sx={css`width: 100%;`}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment>
                                                <IconButton className="p-0" size="large">
                                                    <Calendar size={14} color={SECONDARY_COLOR} />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                ></ModalDatePicker>
                            </LocalizationProvider>
                        </FormControl>
                            :
                            variableName?.includes("cnaeTypeId") ?
                                <FormControl fullWidth
                                    variant="outlined"
                                    size="small">
                                    <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>CNAE</FormLabel>
                                    <ModalSelectForm
                                        variant="outlined"
                                        size="small"
                                        value={valueVariable}
                                        onChange={onChangeVariable}
                                        placeholder="Selecione"
                                        sx={css`white-space: normal;`}
                                    >
                                        {cnaesOptions}
                                    </ModalSelectForm>
                                </FormControl> :
                                variableName?.includes("ANTT") || variableName?.includes('statusSocialContractId') ?
                                    <FormControl
                                        fullWidth
                                        variant="outlined"
                                        size="small">
                                        <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Status ANTT</FormLabel>
                                        <ModalSelectForm
                                            variant="outlined"
                                            size="small"
                                            value={valueVariable}
                                            onChange={onChangeVariable}
                                            placeholder="Selecione"
                                            sx={css`white-space: normal;`}
                                        >
                                            {anttOptions}
                                        </ModalSelectForm>
                                    </FormControl> :
                                    <FormControl component={'fieldset'} sx={css`width:100%;`}>
                                        <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Valor</FormLabel>
                                        <ModalTextField value={valueVariable} onChange={onChangeVariable} />
                                    </FormControl>
                        }
                    </Grid>
                    <Grid md={7} sm={8} xs={4}>
                        <Stack direction={'row'} spacing={2} sx={{ marginTop: '25px', marginLeft: '10px' }}>
                            <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;margin-top:20px !important;border-color:${LIGHT_GRAY};background-color: ${WHITE};`}>
                                <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e)} />
                            </Button>
                            <Typography sx={css`margin-top:20px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{file && file.name}</Typography>
                            {file &&
                                <IconButton sx={css`margin-top:20px !important;`} onClick={() => handleFile(null)}>
                                    <Trash2 color={RED} />
                                </IconButton>
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </BodyDialog>
            <DialogActions sx={css`display: flow;`}>
                <ConfirmButton onClick={() => sendFile(variableName)}>
                    ENVIAR DOCUMENTO
                </ConfirmButton>
                <CancelButton onClick={onCancel}>
                    CANCELAR
                </CancelButton>
            </DialogActions>
        </Spin>
    </UploadDialog>
}

const UploadDialog = styled(Dialog)`
    .MuiDialogContent-root{   
        overflow-x: hidden;
        @media screen and (max-device-width: 768px){
            margin-right: 2%;
        }
    }
    .MuiPaper-root {
        width: auto;
        max-width: 100%;
        @media screen and (min-device-width: 769px){
            width: 40%;
        }
    }
`

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default UploadModal;