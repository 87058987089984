import { Button, Dialog, DialogActions, DialogTitle, FormControl, FormLabel, Grid, IconButton, TextField, Typography } from "@mui/material";
import styled from "@emotion/styled"
import { BodyDialog, HeaderDialog } from "../../../usersList/view/NewAccessModal";
import { css } from "@emotion/react";
import { Close } from "@mui/icons-material";
import { CancelButton, ConfirmButton } from "../../../carrierDetails/views/RequestEditCarrier";
import { Textarea } from "@mui/joy";
import { LIGHT_BLUE, LIGHT_GRAY, RED, WHITE } from "../../../../theme";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Trash2 } from "react-feather";
import { Stack } from "@mui/system";
import { Spin } from "antd";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const AddLetterModal = ({ load, open, onClose, titleValue, onChangeTitle, contentValue, onChangeContent, sendLetter, doc, handleFile, onCancel }) => {

    return <LetterDialog
        open={open}
        onClose={onClose}
    >
        <Spin spinning={load}>
            <HeaderDialog>
                <DialogTitle sx={css` color: white;`}>Adicionar Comunicado </DialogTitle>
                <Close sx={css`cursor: pointer;color: white;margin-right: 16px;`} onClick={onClose} />
            </HeaderDialog>
            <BodyDialog>
                <Grid container spacing={5} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item md={12} sm={8} xs={4}>
                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Titulo</FormLabel>
                            <TextField sx={css`&.MuiTextField-root{height: 41px; width: 100%;}& .MuiOutlinedInput-root{background: white;}`} value={titleValue} onChange={onChangeTitle} />
                        </FormControl>
                    </Grid>
                    <Grid item md={12} sm={8} xs={4}>
                        <FormControl component={'fieldset'} sx={css`width:100%;`}>
                            <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Comunicado</FormLabel>
                            <Editor
                                editorState={contentValue}
                                wrapperClassName="demo-wrapper"
                                editorClassName="demo-editor"
                                onEditorStateChange={onChangeContent}
                                editorStyle={{backgroundColor: '#FFFFFF', padding: '20px'}}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item md={3} sm={4} xs={4}>
                        <Stack direction={'row'}>
                            <FormControl component={'fieldset'} sx={css`width:100%;`}>
                                <FormLabel component={'legend'} sx={css`font-size:14px;font-family: YaraMax;`}>Anexo</FormLabel>
                                <Button component="label" variant="outlined" sx={css`height: 41px;width: 41px;margin-top:20px !important;border-color:${LIGHT_BLUE};background-color: ${WHITE}`}>
                                    <AttachFileIcon sx={css`transform: rotate(40deg);`} />
                                    <VisuallyHiddenInput type="file" onChange={(e) => handleFile(e)} />
                                </Button>
                            </FormControl>
                            <Typography sx={css`margin-top:50px !important;font-size:12px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;`} variant="caption">{doc && doc.name}</Typography>
                            {doc &&
                                <IconButton sx={css`margin-top:45px !important;`} onClick={() => handleFile(null)}>
                                    <Trash2 color={RED} />
                                </IconButton>
                            }
                        </Stack>
                    </Grid>
                </Grid>
            </BodyDialog>
            <DialogActions sx={css`display: flow;`}>
                <ConfirmButton onClick={sendLetter}>
                    PUBLICAR COMUNICADO
                </ConfirmButton>
                <CancelButton onClick={onCancel}>
                    CANCELAR
                </CancelButton>
            </DialogActions>
        </Spin>
    </LetterDialog>
}

const LetterDialog = styled(Dialog)`
    .MuiDialogContent-root{   
        overflow-x: hidden;
        @media screen and (max-device-width: 768px){
            margin-right: 2%;
        }
    }
    .MuiPaper-root {
        width: auto;
        max-width: 100%;
        @media screen and (min-device-width: 769px){
            width: 60%;
        }
    }
`

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export default AddLetterModal;