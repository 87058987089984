import { useRef, useState } from "react"
import NewsLetterService from "../../../services/NewsLetterService";
import { notification } from "antd";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

const useAddNewLetter = (loadList) => {
    const [openModal, setOpenModal] = useState(false);
    const [titleValue, setTittleValuer] = useState('');
    const [contentValue, setContentValue] = useState(EditorState.createEmpty());
    const [doc, setDocument] = useState();
    const [apiNotification, contextHolder] = notification.useNotification();
    const [load, setLoad] = useState(false);

    const onChangeTitle = (e) => {
        setTittleValuer(e.target.value);
    }
    const onChangeContent = (e) => {
        setContentValue(e);
    }

    const sendLetter = async () => {
        if (titleValue !== '' && contentValue !== '') {
            setLoad(true)
            await NewsLetterService.sendNewLetter(titleValue, draftToHtml(convertToRaw(contentValue.getCurrentContent()))).then(resp => {
                console.log(resp)
                if (doc) {
                    const form = new FormData();
                    form.append('file', doc);

                    NewsLetterService.sendAttache(resp.data, {
                        "file": form
                    })
                }
                apiNotification['success']({
                    message: 'Publicação realizada com sucesso!',
                    placement: 'topRight',
                    duration: 5
                });

                setTimeout(()=>{
                    loadList();
                    onCancel();
                    setLoad(false);
                }, 1000)
            })
        } else {
            apiNotification['Warnning']({
                message: 'É necessário alterar o título e o comentário para realizar a publicação.',
                placement: 'topRight',
                duration: 5
            });
        }
    }

    const onChangeModalState = () => {
        setOpenModal(!openModal);
    }

    const handleFile = (e) => {
        const fileValue = e?.target?.files ? Array.from(e.target.files)[0] : null;

        setDocument(fileValue)
    }

    const onCancel = () => {
        setTittleValuer('')
        setContentValue('')
        setOpenModal(false);
    }

    return {
        openModal,
        titleValue,
        contentValue,
        doc,
        load,
        onCancel,
        handleFile,
        sendLetter,
        contextHolder,
        onChangeTitle,
        onChangeContent,
        onChangeModalState
    }
}

export default useAddNewLetter;